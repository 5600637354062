import { render, staticRenderFns } from "./medistore.vue?vue&type=template&id=63309900&scoped=true&"
import script from "./medistore.vue?vue&type=script&lang=js&"
export * from "./medistore.vue?vue&type=script&lang=js&"
import style0 from "./medistore.vue?vue&type=style&index=0&id=63309900&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63309900",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TheHeaderV2: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/TheHeaderV2.vue').default,ThePages: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/ThePages.vue').default,TheFooterV2: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/TheFooterV2.vue').default,BaseToast: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/toast/BaseToast.vue').default})
