import { render, staticRenderFns } from "./PriceField.vue?vue&type=template&id=526d4daf&scoped=true&"
import script from "./PriceField.vue?vue&type=script&lang=js&"
export * from "./PriceField.vue?vue&type=script&lang=js&"
import style0 from "./PriceField.vue?vue&type=style&index=0&id=526d4daf&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "526d4daf",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MediClubLogo: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/MediClub/MediClubLogo.vue').default,MediClubLogoInline: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/MediClub/MediClubLogoInline.vue').default,MediClubLogoSponsored: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/MediClub/MediClubLogoSponsored.vue').default,BaseIcon: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseIcon.vue').default})
